import { useCallback, useEffect, useState } from 'react'

import { useCartContext } from 'bl-common/src/hooks/useCartContext'

export const useCartExpiration = ({
  onExpired,
  expirationTimeInMs,
  expirationWarningTimeInMs,
  warningModal,
  checkIntervalInMs = 1000,
}: {
  onExpired: () => Promise<void>
  expirationTimeInMs: number
  expirationWarningTimeInMs: number
  warningModal: () => Promise<boolean>
  checkIntervalInMs?: number
}) => {
  const { cartLastUpdated, cart, getCart } = useCartContext()
  const [timeLeftInMs, setTimeLeftInMs] = useState(expirationTimeInMs)
  const [isShowingExpirationWarning, setIsShowingExpirationWarning] =
    useState(false)

  const checkExpiration = useCallback(() => {
    const timeNowInMs = Date.now()
    const timeDifferenceInMs = timeNowInMs - cartLastUpdated

    if (
      cartLastUpdated &&
      timeDifferenceInMs >= expirationTimeInMs &&
      cart?.items?.length
    ) {
      onExpired()
    } else if (cart?.items?.length === 0) {
      // If we don't have any items, set the remaining time to the full expiration time.
      setTimeLeftInMs(expirationTimeInMs)
    } else {
      // Otherwise, set the remaining time to the difference between the expiration time and the time difference.
      // 0 means that the cart has expired.
      setTimeLeftInMs(Math.max(expirationTimeInMs - timeDifferenceInMs, 0))
    }
  }, [cartLastUpdated, cart?.items, expirationTimeInMs, onExpired])

  // This effect will check if the cart has expired every X seconds.
  // If it has, it will call the onReset function.
  useEffect(() => {
    const expirationInterval = setInterval(checkExpiration, checkIntervalInMs)
    return () => {
      clearInterval(expirationInterval)
    }
  }, [checkExpiration])

  const checkIfActive = useCallback(async () => {
    // If the cart is empty or we're already showing the warning, don't show it again.
    if (cart?.items?.length === 0 || isShowingExpirationWarning) {
      return
    }
    setIsShowingExpirationWarning(true)
    await warningModal()

    // Fetch cart to reset expiration timer
    // Apollo is smart enough to only fetch the cart from cache.
    await getCart()

    setIsShowingExpirationWarning(false)
  }, [cart, isShowingExpirationWarning])

  // Effect that triggers a warning if the cart is about to expire.
  useEffect(() => {
    if (
      timeLeftInMs <= expirationWarningTimeInMs &&
      cart?.items?.length !== 0
    ) {
      checkIfActive()
    }
  }, [timeLeftInMs, cart])

  return { timeLeftInMs }
}
