import { useEffect, useRef } from 'react'
import { useLocalStorageValue } from '@react-hookz/web'
import isNumber from 'lodash/isNumber'

const CART_UPDATE_UNSET_VALUE = -1

export const useSessionTimeout = (
  id: string,
  onReset: () => Promise<void>,
  timeoutTimerMs: number
) => {
  const [lastCartUpdate, setLastCartUpdate] = useLocalStorageValue<number>(
    `flow_${id}_last-cart-update`,
    CART_UPDATE_UNSET_VALUE,
    {
      storeDefaultValue: true,
      initializeWithStorageValue: true,
    }
  )
  const sessionResetTimeout = useRef(null)

  const clearTimer = () => {
    if (sessionResetTimeout.current) {
      clearTimeout(sessionResetTimeout.current)
    }
  }

  const stopTimer = (force = true) => {
    if (!force && lastCartUpdate !== CART_UPDATE_UNSET_VALUE) {
      return
    }

    clearTimer()
    setLastCartUpdate(CART_UPDATE_UNSET_VALUE)
  }

  const startTimer = (timeInMs: number, force = true) => {
    const previousTimerHasBeenSet = lastCartUpdate !== CART_UPDATE_UNSET_VALUE

    if (force || !previousTimerHasBeenSet) {
      setLastCartUpdate(Date.now())
    }

    clearTimer()
    sessionResetTimeout.current = setTimeout(async () => {
      await onReset()
      setLastCartUpdate(CART_UPDATE_UNSET_VALUE)
    }, timeInMs)
  }

  useEffect(() => {
    let lastUpdate = lastCartUpdate

    if (!isNumber(lastUpdate) || lastUpdate < 0) {
      lastUpdate = CART_UPDATE_UNSET_VALUE
    }

    const hasExistingTimer = lastUpdate !== CART_UPDATE_UNSET_VALUE

    if (hasExistingTimer) {
      const sessionShouldBeCancelledAt = lastUpdate + timeoutTimerMs
      const msUntilCancel = Math.max(sessionShouldBeCancelledAt - Date.now(), 0)

      startTimer(msUntilCancel, false)
    }
  }, [])

  return {
    lastCartUpdate,
    stopTimer,
    startTimer,
  }
}
